.login-body {
  min-height: calc(100vh - 300px);
  color: white;
  padding: 60px 20px 10px 20px;
}

.login-container>div {
  margin-top: 150px !important;
  overflow: hidden;
  border: 1px solid white;
  border-radius: 20px !important;
  background-image: url(../../back.png);
  box-shadow: 0px 0px 16px #ffffff88;
}

.login-button {
  width: 100%;
  font-size: 18px;
  font-weight: bold;
}

.login-body input,
.login-button {
  box-shadow: -2px 4px 4px black;
}

.login-button:active{
  box-shadow: -2px 4px 1px black;
}

.login-body input {
  border-radius: 0px;
  border: 3px inset #dedede;
}

