.week-pick-grid{
  display:grid;
  grid-template-columns:repeat(7, 1fr);
}

.week-pick-grid > div{
  justify-self: center;
  user-select: none;
  cursor:pointer;
  display:flex;
  justify-content:center;

}

.weekpicker-cell{
  width:100%;
  padding:3px;
}

.weekpicker-cell.active {
  box-sizing: border-box;
  background-color: #0000ff55;
}

.week-accordion-head{
  margin:0px;
}

.week-accordion-head > button{
  padding:5px;
}