img{
  max-width: 100%;
}

.navbar-app {
  height: 100px;
  background-image: url(cabecera.jpg);
  background-repeat: repeat-y;
  background-size:contain;
  border-bottom:1px solid rgb(116, 173, 175);
}

.main-container {
  color:white;
  background-image: url(back.png);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - 100px);
  padding: 0!important;
  margin:0 !important;
}
a.nav-link{
  color:#c1bdbd;
  padding: 7px 16px;
}
a.nav-link.active{
  color: #FFF !important;
  background-color: #13191f;
  border-radius: 5px;
  height: fit-content;
}

