.date-head{
  font-size: 30px;
  font-weight: bolder;
  color:rgb(167, 225, 233);
}

.row {
  justify-content:center;
}

.release-image{
  float:right;
  max-height:30vh;
  min-height:100%;
}

.heatmap-release{
  height: 300px;
 }

 .heatmap-box{
  margin:10px;
  margin-left:0px;
  border:1px inset #000000aa;
  border-radius:10px;
  background-color: #92929244;
  padding-left:30px !important;
 }

 .release-date-box{
  background-color:#f7afaf66;
 }