.tabs-comparative .tab-content {
    background-color: #FFF;
    padding-top: 30px;
}

.tab-bubble .h3 {
    color: #FFF !important;
}

.date-head-picker {
    padding: 5px;
    justify-content: flex-start;
}

.ps-button.active{
    background-color: #21262a !important;
    color: #FFF !important;
}

.buttondownload{
    width: 180px !important;
}

.tabs-comparative svg{
    margin-right: 10px;
}

.tabs-comparative span{
    font-size: 14px;
    font-weight: bold;
}

.disabled-controls{
    pointer-events: none;
    opacity:0.6;
}
.custom-input-date{
 width: 80%;
 }
 .calendar-container .date-head-picker{
    margin-left: -5px;
 }

