.billboard {
  color: rgb(211, 212, 212);
}

.month-days {
  box-sizing: border-box;
  display: grid !important;
  grid-template-columns: repeat(7, 1fr);
}

.weekday,
.day-container {
  margin: 0 !important;
  padding: 0 !important;
}

.weekday .rowdays {
  text-align: center;
}

.weekday:nth-child(7)>.rowdays {
  margin-right: 0% !important;
  background-color: #536367;
}

.weekday:nth-child(6)>.rowdays,
.weekday:nth-child(7)>.rowdays {
  background-color: #536367;
  margin-right: 0 !important;
}



.day-container {
  border: 1px solid #4c626a;
  position: relative;
  box-sizing: border-box;
  min-height: 180px;
}

.img-container {
  text-align: center;
}

.daynumber {
  font-size: 12px;
  margin-top: 10px !important;
}

.divincalendar {
  display: block;
}

.divincalendar img {
  max-width: fit-content;
  height: fit-content;
}

.daycard-day {
  position: absolute;
  right: 10px;
  top: 10px;
  width: auto !important;
  font-size: 15px;
  font-weight: bolder;
  user-select: none;
  background-color: #00000066;
  border-radius: 20%;
  z-index: 2;
  transition-duration: 0.5s;
}

.h3calendar {
  color: #FFF;
  display: inline;
}

.nav-fill .nav-item,
.nav-fill>.nav-link {
  background-color: #637a87;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}

.nav-tabs .nav-link:hover {
  border-color: transparent !important;
  color: #FFF;
  background-color: #566d7a;
}

.nav-tabs .nav-link {
  color: #FFF;
}

.titlecalendar {
  font-size: 14px;
  text-transform: uppercase;
}

.flabel {

  border-radius: 10px;
  user-select: none;
  padding-right: 3px;
  cursor: pointer;
  background-color: #ff000022;
}

.flabel.active {
  cursor: pointer;
  background-color: #00ff0022;
}

.flabel:nth-child(2) {
  margin-left: 20px;
}

.month-nav {
  user-select: none;
  cursor: pointer;
  font-size: 16;
  font-weight: bolder;
  width: 140px;
  justify-content: center;
  display: block;

}

.filter-date {
  user-select: none;
  cursor: pointer;
  padding-bottom: 10px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  transition-duration: 0.5s;
  border-bottom: 5px solid #ffffff00;
}

.filter-date.active {

  border-bottom: 5px solid #00ff0088;
}

.date-select-filter {
  padding-top: 20px;
}

.title-section {
  padding-top: 20px;
}

.title-section h4 {
  display: inline;
}

.title-section .llave {
  display: inline;
}

.imagetitlesection {
  height: 60px;
  margin-right: 25px;
  display: inline;
}

.llave {
  height: 57px;
  width: 35px;
}

.ep-ball {
  background-color: #56947b;
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.start-ep {
  background-color: #e9d090
}

.popover-calendar-episodes {
  max-width: 100% !important;
}

.episodes-view-pop {
  padding: 1vw;
}

.chapter-history-info {
  font-size: 16px !important;
  font-weight: normal !important;
  margin-bottom: 28px !important;
}


.history-month-container .weekday:nth-child(7)>.rowdays {
  background-color: #c9d2d5 !important;
}

.calendarmini {
  border: 1px solid #CCC;
  padding: 11px;
}

.day-container,
.img-calendar-box {
  padding: 0px 4px;
}

.imgcalendar {
  box-shadow: 3px 3px 3px black;
  width: 100% !important;
}

.imgcalendar:hover {
  box-shadow: 5px 5px 5px black;
}

.date-nav-monthly {
  height: 100%;
  width: 400px;
}

.date-nav-monthly>div {
  align-items: flex-end;
  justify-content: center;
}

.date-nav-monthly>div:first-child {
  font-size: 30px;
  font-weight: bold;
}

.date-nav-monthly>div:nth-child(3) button {
  display: flex;
  width: 100%;

}

.head-month-info {
  z-index: 3;
}

.daycard-container{
  position:relative;
}

.daycard-container::after {
  content: "";
  width: calc(100% - 7px);
  height: 6px;
  position: absolute;
  bottom:-6px;
  left:4px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.second-filter{
  margin-bottom: 5px;
}
/* .second-filter div{
  color:black
} */



.daycard-container.platform-amazon::after{
  background-color: #48a3c6;
}

.daycard-container.platform-netflix::after{
  background-color: #e50914;
}

.daycard-container.platform-disney::after{
  background-color: #E6F72C;
}

.daycard-container.platform-euskaltel::after{
  background-color: #e978d8;
}

.daycard-container.platform-hbo::after{
  background-color: #4826c2;
}

.daycard-container.platform-movistar::after{
  background-color: #66cd00;
}

.daycard-container.platform-orange::after{
  background-color: #fd8204;
}

.daycard-container.platform-vodafone::after{
  background-color: white;
}
