.chapter-history-title,
.chapter-history-info {
  color: black;
  font-size: 24px;
  font-weight: bold;
  margin-top:10px;
  margin-bottom: 5px;
}


.history-month-container {
  white-space: nowrap;
}

.history-month-container>div {
  display: inline-block;
  margin:5px;

}

.cgrid-cell {
  width: 100;
  justify-content: center;
  display: flex;
}