.filter-source-container {
  width: 300px;
  background-color: #ffffff11;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 17px #00000033;
  font-size: 30px;
  
}

.filter-source-container >div{
  overflow:hidden;
}


.filter-source-container > div{
  display:flex;
  justify-content: center;
} 

.amazon-icon {
  mask-image: url(../../resources/images/prime-video.png);
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
  background-color: #ffffff88;
  width: 30px;
  height: 30px;
}


.disney-icon {
  mask-image: url(../../resources/images/Disney.svg);
  mask-repeat: cover;
  mask-size: 100% 100%;
  background-color: #ffffff88;
  width: 60px;
  height: 100px;
}

.euskaltel-icon {
  mask-image: url(../../resources/images/Euskaltel.svg);
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
  background-color: #ffffff88;
  width: 40px;
  height: 30px;
}

.movistar-icon {
  mask-image: url(../../resources/images/Movistar.svg);
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
  background-color: #ffffff88;
  width: 40px;
  height: 30px;
}

.orange-icon {
  mask-image: url(../../resources/images/Orange.svg);
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
  background-color: #ffffff88;
  width: 50px;
  height: 60px;
}

.hbo-icon {
  mask-image: url(../../resources/images/HBO_Max.svg);
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
  background-color: #ffffff88;
  width: 50px;
  height: 60px;
}


.filter-source-control {
  cursor: pointer;
  padding: 3px;
  border-radius: 10px;
  background-color: #ffffff11;
  text-align: center;
  height: 60px;
  width: 60px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 3px solid #ffffff99;
  transition-duration: 0.25s;
  overflow: hidden;
}


.filter-source-control:not(.filtered):hover {
  box-shadow: 0px 0px 4px yellow;
}

.filter-source-control:active{
  padding:0;
  
}

.filter-source-control:active > svg{
  font-size:35px !important;
}



.filter-source-control>svg {
  color: #ffffff66;
}

.filter-source-control.filtered {
  background-color: #ffffffdd;
  border: 1px solid #00000099;
}

.filter-source-control.filtered>div {
  background-color: #000000aa !important;
}

.filter-source-control.filtered>svg {
  color: #000000aa;
}

.filter-source-container.channels .filter-source-control{
  font-size: 13px;
}

.filter-source-container.channels .filter-source-control > span{
  transform:rotate(22deg);
}

.channel-filter{
  min-width: 100px;
  color:black;
}

.filter-source-control::after{
  content:"";
  width:100%;
  height:10px;
  position: absolute;
  left:0px;
  bottom:0px;
}

.filter-source-control.control-amazon::after{
  background-color: #48a3c6;
}

.filter-source-control.control-netflix::after{
  background-color: #e50914;
}

.filter-source-control.control-disney::after{
  background-color: #E6F72C;
}

.filter-source-control.control-euskaltel::after{
  background-color: #e978d8; 
}

.filter-source-control.control-hbo::after{
  background-color: #4826c2;
}

.filter-source-control.control-movistar::after{
  background-color: #66cd00;
}

.filter-source-control.control-orange::after{
  background-color: #fd8204;
}

.filter-source-control.control-vodafone::after{
  background-color: white;
}
